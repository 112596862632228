import { SiteSettings } from '@/services/graphql';
import {
  useMemo, createContext, FC, ReactNode,
} from 'react';

export const SiteSettingsContext = createContext<SiteSettings>({});

export const SiteSettingsProvider: FC<{ values:SiteSettings,children:ReactNode }> = ({values,children}) => {
  const SiteSettingsContextValue = useMemo<SiteSettings>(() => values, [values]);

  console.log('🚀 ~ file: siteSettingsProvidet.tsx:17 ~ values', values);

  return (
    <SiteSettingsContext.Provider value={SiteSettingsContextValue}>
      {children}
    </SiteSettingsContext.Provider>
  );
};
