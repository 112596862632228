
declare global {
  interface Window {
    gtag: any;
  }
}

export const pageview = (url: string, gaId: string) => {
  if (window && window?.gtag) {
    window?.gtag('config', gaId, {
      page_path: url,
    });
  }
};
