import '@/styles/globals.css';
import App, { AppContext, AppProps } from 'next/app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Head from 'next/head';
import { getSdk, SiteSettings } from '@/services/graphql';
import { graphqlClient } from '@/services/graphql-client';
import { SiteSettingsContext } from '@/providers/siteSettingsProvider';
import 'keen-slider/keen-slider.min.css';
import '@/fonts/icomoon/style.css';
import 'yet-another-react-lightbox/styles.css';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { pageview } from '@/utils/pageview';
import Script from 'next/script';

const theme = {
  sizes: {
    container: {
      xl: '1440px',
    },
  },
  colors: {
    primary: '#a29060',
    'primary-800': '#a29060aa',
    'primary-100': '#a2906011',
    'primary-200': '#a2906033',
    MenuText: '#fff',
    'gray-800': '#212529',
  },
  fonts: {
    heading: 'Merriweather',
    body: 'Open Sans',
    menubar: 'Merriweather',
  },
};

const themeExtended = extendTheme(theme);

const googleAnalyticId ='G-M4M68ENPBP';

const MyApp = ({ Component, pageProps }: AppProps<{ siteSettings: SiteSettings }>) => {
  const router = useRouter();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (googleAnalyticId) {
      const handleRouteChange = (url:string) => {
        pageview(url, googleAnalyticId);
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router.events]);

  return(
    <SiteSettingsContext.Provider value={pageProps.siteSettings}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{pageProps.siteSettings.title}</title>
        <meta name="description" content={pageProps.siteSettings.description || ''} />
      </Head>
      {!!googleAnalyticId && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${googleAnalyticId}', {
                    page_path: window.location.pathname,
                  });
                `,
            }}
          />
        </>
      )}
      <ChakraProvider theme={themeExtended}>
        <Component {...pageProps} />
      </ChakraProvider>
    </SiteSettingsContext.Provider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const sdk = getSdk(graphqlClient);
  const { data } = await sdk.getSiteSettigns();

  await App.getInitialProps(appContext);

  return {
    pageProps: {
      siteSettings: data.SiteSettings,
    },
  };
};

export default MyApp;
